import { Popover, Transition } from "@headlessui/react";
import { ChartBarIcon, CursorClickIcon, ShieldCheckIcon, ViewGridIcon, RefreshIcon, PlayIcon, PhoneIcon, SupportIcon, BookmarkAltIcon, CalendarIcon, MenuIcon, ChevronDownIcon, XIcon, CheckIcon } from "@heroicons/react/outline";
import * as React from "react";
import Layout from "../components/layout";
import { H1 } from "../components/typography";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const solutions = [
  {
    name: 'Analytics',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '#',
    icon: CursorClickIcon,
  },
  { name: 'Security', description: "Your customers' data will be safe and secure.", href: '#', icon: ShieldCheckIcon },
  {
    name: 'Integrations',
    description: "Connect with third-party tools that you're already using.",
    href: '#',
    icon: ViewGridIcon,
  },
  {
    name: 'Automations',
    description: 'Build strategic funnels that will drive your customers to convert',
    href: '#',
    icon: RefreshIcon,
  },
]

const features = [
  'Smart investment strategies.',
  'AI powered investing.',
  'Informative articles.',
  'Simple interface.',
  'Investment amount suggestions powered by AI.',
  'Great customer support.',
  'ISA accounts.',
  'Crypto-currencies .',
  'Excellent insights.',
  'Tips and feedback.',
]
const faqs = [
  {
    id: 1,
    question: "How do you calculate my investment and saving amounts?",
    answer:
      "We base our suggestions on your spending ensuring you have enough for recurring payments and essentials",
  },
  {
    id: 2,
    question: 'What if I can\'t invest what you suggest ',
    answer:
      "We've got you covered, we offer multiple suggestions and you can always pick an amount your comfortable with ignoring these .",
  },
  {
    id: 3,
    question: 'How do I start?',
    answer:
      'Download our app and get signed up. It only takes a few minutes',
  },
  {
    id: 4,
    question: "What if I experience and issue?",
    answer:
      'We have a fantastic support team. Please head over to our support page to get into touch so we can sort this out for you',
  },
  {
    id: 5,
    question: 'Do you charge commission?',
    answer: 'We don\'t charge commission on trades but please be aware that there are spread fees which is common amongst all brokers.',
  },
  {
    id: 6,
    question: 'When\'s the best time to start?',
    answer:
      "Yesterday! The second best day is today",
  },
]

const IndexPage = () => (
  <Layout
    title="Market news, financial education and more"
    description="Vessel learn teaches and informs you about anything and everything financial. Our articles are written by experts and designed to help you on your financial journey"
    canonical="why-vessel"
    siteName="Vessel"
  >
      <div className="bg-gradient-to-b from-blue-50 via-white to-white mt-12">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row pb-16 lg:flex lg:items-center lg:justify-between">
            <div>
              <H1 className="text-4xl md:text-5xl ">
                <span className="text-gray-900">Everything you need to </span>
                <span className="text-blue-500">save and invest</span>
              </H1>
              <p className="mt-5 text-xl text-gray-500">
                Join the holistic investment and saving app.
              </p>
            </div>
            <a
              href="#"
              className="mt-8 mx-5 w-3/5 min-w-max self-center lg:self-start bg-blue-500 border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base font-medium text-white hover:bg-blue-600 sm:mt-10 sm:w-auto lg:mt-1"
            >
              Get started today
            </a>
          </div>
          <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8 mt-24">
            <div>
              <h2 className="text-base font-semibold text-blue-500 uppercase tracking-wide">Everything you need</h2>
              <p className="mt-2 text-3xl font-extrabold text-gray-900">All-in-one platform</p>
              <p className="mt-4 text-lg text-gray-500">
                Unlike other brokerages, we help you navigate all your finances. Giving you tips on where to cut back and ultimately ensuring you're able to invest and save better.
              </p>
            </div>
            <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2">
              <ul className="divide-y divide-gray-200">
                {features.slice(0, 5).map((feature, featureIdx) => (
                  <li key={feature} className={classNames(featureIdx === 0 ? 'md:py-0 md:pb-4' : '', 'py-4 flex')}>
                    <CheckIcon className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
              <ul className="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
                {features.slice(5).map((feature, featureIdx) => (
                  <li
                    key={feature}
                    className={classNames(featureIdx === 0 ? 'md:border-t-0 md:py-0 md:pb-4' : '', 'py-4 flex')}
                  >
                    <CheckIcon className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Branded FAQ */}
      <div className="bg-blue-500 mt-24">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white">Frequently asked questions</h2>
          <div className="mt-6 border-t border-blue-400 border-opacity-25 pt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              {faqs.map((item) => (
                <div key={item.id}>
                  <dt className="text-lg leading-6 font-medium text-white">{item.question}</dt>
                  <dd className="mt-2 text-base text-blue-50">{item.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {/* CTA section */}
      <div className="my-24">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-blue-900 sm:text-4xl">
            <span className="block">Ready to dive in?</span>
            <span className="block text-blue-500 mt-2 text-2xl sm:text-3xl">Get started today.</span>
          </h2>
          <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="#"
                className="bg-blue-600 border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base font-medium text-white hover:bg-blue-700"
              >
                Get the app
              </a>
            </div>
          </div>
        </div>
      </div>
  </Layout>
);

export default IndexPage;
